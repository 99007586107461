import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import useAuth from '../../hooks/useAuth';

export const FloatingWhatsAppButton = () => {
  const { user } = useAuth();

  const handleMouseEnter = (e) => {
    e.currentTarget.style.focus = 'none';
    e.currentTarget.style.transform = 'translateY(-2px)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.focus = 'none';
    e.currentTarget.style.transform = 'translateY(0)';
    e.currentTarget.style.boxShadow = '0 8px 16px 0 rgba(0,171,85,0.24)';
  };

  return (
    <a
      href={`https://wa.me/5569981181433?text=Olá! Eu sou ${user.name}. Estou precisando de ajuda. `}
      style={{
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        bottom: '30px',
        right: '40px',
        backgroundColor: '#00AB55',
        color: '#fff',
        borderRadius: '24px',
        padding: '8px 16px',
        textDecoration: 'none',
        fontSize: '14px',
        fontFamily: "'Public Sans', sans-serif",
        zIndex: 1000,
        transition: 'all 0.2s ease',
        border: '1px solid rgba(0,171,85,0.1)',
        cursor: 'pointer'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon icon="ic:baseline-whatsapp" style={{ fontSize: '22px' }} />
      <span style={{ fontWeight: 600 }}>Precisa de Suporte?</span>
    </a>
  );
};
